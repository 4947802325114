export const environment = {
  name: 'prod',
  userPoolId: 'ap-south-1_VjUGnZ2q1',
  userPoolClientId: '15lveak40j8svul787e2kuns9h',
  cognitoDomain: 'prod-rewaa-admin.auth.ap-south-1.amazoncognito.com',
  scopes: [
    'phone',
    'email',
    'openid',
    'profile',
    'aws.cognito.signin.user.admin',
  ],
  redirectSignIn: [`${window.location.origin}/accounts`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  launchDarklyClientSideId: '6345187d3d73f711242249d4',
  crmAccountUrl: 'https://crm.zoho.com/crm/org709483323/tab/Accounts/',
  chargebeeAccountUrl: 'https://rewaa.chargebee.com/d/customers/',
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  appVersion: require('../../package.json').version,
  enableDatadog: true,
  enableFullStory: true,
  datadogApplicationId: 'dea2a987-fdaa-49b3-aff9-b1840fe4b2bc',
  datadogClientToken: 'pub4e8cd88ba279a0885c9643c29c611449',
};
